class MobileBarHeight {
  constructor() {
    this.vh = 0;
    this._createStyleElement();
    this._setVh();

    if (window.membrana.theme.mobileBarFix.update) {
      this._bindEvents();
    }
  }

  _setVh() {
    this.vh = window.innerHeight * 0.01;
    $('#membrana-fix-bar').html(`:root { --fix-bar-vh: ${this.vh}px; }`);
  }

  _bindEvents() {
    const event = getResponsiveResizeEvent();

    window.$window.on(event, debounce(() => {
      this._setVh();
    }, 250));
  }

  _createStyleElement() {
    if (!$('#membrana-fix-bar').length) {
      $('head').append('<style id="membrana-fix-bar"></style>');
    }
  }
}
