const BARBAJAXTransitionOverlayMenu = {
  name: 'overlayMenu',

  custom: ({
    trigger
  }) => {
    const $trigger = $(trigger);
    return window.membrana.theme.header.isOverlayOpened() || ( $trigger.attr('href') !== '#' && $trigger.attr('data-BARBAJAX-link') === 'overlayMenu' );
  },

	before: (data) => {
    return new Promise((resolve) => {
			BARBAJAXStartLoading(data).then(() => resolve(true));
		});
	},

	beforeEnter: (data) => {
		return new Promise((resolve) => {
			BARBAJAXSetNextContainer(data)
				.then(() => BARBAJAXAnimateOverlayMenu(data))
				.then(() => BARBAJAXSetCurrentContainer(data, false, false))
				.then(() => resolve(true));
		});
	},

	enter: (data) => {
		return new Promise((resolve) => {
			BARBAJAXInitNewPage(data).then(() => resolve(true));
		});
	},

	afterEnter: (data) => {
		return new Promise((resolve) => {
			BARBAJAXClearContainer(data).then(() => resolve(true))
		});
	},

	after: (data) => {
		return new Promise((resolve) => {
			BARBAJAXFinishLoading(data).then(() => resolve(true));
		});
	}

}
