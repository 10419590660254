/**
 * performance GPU 
 */
runOnHighPerformanceGPU();

/**
 * GSAP plugins
 */
gsap.config({ nullTargetWarn: false });
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

/**
 * Global Vars
 */
window.$document = $(document);
window.$window = $(window);
window.$body = $("body");
window.$html = $("html");
window.$pageHeader = $("#page-header");
window.$pagePreloader = $("#js-preloader");
window.$pageWrapper = $("#page-wrapper");
window.$pageContent = $(".page-wrapper__content");
window.$barbaWrapper = $('[data-barba="wrapper"]');
window.$cursor = $("#js-membrana-cursor");
window.$spinner = $("#js-spinner");

/**
 * Options
 */
if (typeof window.membrana === "undefined") {
  window.membrana = {
    loading: new Preloader(),
    theme: {
      ajax: {
        enabled: true,
        preventRules: "", // jQuery selectors of the elements to exclude them from AJAX transitions
        updateNodesAttributes: "",
        updateScriptNodes: "",
        loadMissingScripts: true,
        loadMissingStyles: true,
        evalInlineContainerScripts: false,
      },
      animations: {
        triggerHook: 0.15,
        timeScale: {
          // slow down or speed up the animations
          onScrollReveal: 1.0,
          overlayMenuOpen: 1.0,
          overlayMenuClose: 1.5,
          preloader: 1.0,
          ajaxFlyingImageTransition: 1.0,
          ajaxCurtainTransition: 1.0,
        },
      },
      smoothScroll: {
        enabled: true,
        damping: 0.12,
        renderByPixels: true,
        continuousScrolling: false,
        plugins: {
          edgeEasing: true,
          disableScroll: {
            direction: "x",
          },
        },
      },
      mobileBarFix: {
        enabled: true,
        update: true,
      },
      isFirstLoad: true,
    },
    assets: {
      promises: [],
    },
  };
}

/* Start Preloader */
window.membrana.loading.start();

/**
 * Page Load Strategy Barba.JS
 */
document.addEventListener("DOMContentLoaded", (e) => {
  new Animations();

  // init on each AJAX transition
  if (e.detail) {
    initComponents(e.detail);
  } else {
    // init only on initial page load

    initComponentsOnce({
      scope: window.$document,
    });

    initComponents({
      scope: window.$document,
    });

    setTimeout(() => {
      window.membrana.loading.finish();
    }, 1200);
  }
});

/**
 * Init Components
 * 
 */
function initComponents({
  scope = window.$document,
  container = window.$pageWrapper,
  scrollToHashElement = true,
}) {

  // SCRIPT WORKS MODALS
  var btn_cerrar = document.getElementById("btn_cerrar")
  var popup = document.getElementById("popup")
  var modals = document.getElementsByClassName("modal")
  if (btn_cerrar && popup && modals){

      btn_cerrar.addEventListener('click', ()=>{
          Array.from(modals).forEach(e=>e.classList.remove('open'))
      })
      
      popup.addEventListener('click', ()=>{
          Array.from(modals).forEach(e=>e.classList.add('open'))
      })
  }
  // END WORKS MODALS

  const $smoothScrollContainer = container.filter(".js-smooth-scroll"),
    $sectionMasthead = scope.find(
      ".section-masthead:not(.d-none):not(.js-cancel-init)"
    ),
    $sectionSliderProjects = scope.find(".section-slider-projects"),
    $sectionSliderProjectsFullscreen = scope.find(
      ".section-slider-projects-fullscreen"
    ),
    $sectionSliderImages = scope.find(".section-slider-images"),
    $sectionFixedReveal = scope.find("[data-membrana-fixed-reveal]"),
    $sectionContent = scope.find(".section-content"),
    $sectionTestimonials = scope.find(".section-testimonials"),
    $hoverGroup = scope.find("[data-membrana-hover-class]"),
    $sectionGrid = scope.find(".section-grid"),
    $formContact = scope.find(".js-ajax-form");
    

  if ($smoothScrollContainer.length) {
    new SmoothScroll({
      target: $smoothScrollContainer,
      adminBar: $("#wpadminbar"),
      absoluteElements: $("[data-membrana-scroll-absolute]"), // correct handling of absolute elements OUTSIDE scrolling container
      fixedElements: $("[data-membrana-scroll-fixed]"), // correct handling of fixed elements INSIDE scrolling container
    });
  }

  if ($sectionMasthead.length) {
    $sectionMasthead.each(function () {
      new SectionMasthead({
        target: $(this),
        scope,
      });
    });
  }

  // mobile bottom bar height fix
  if (window.membrana.theme.mobileBarFix.enabled) {
    new MobileBarHeight();
  }

  if ($sectionGrid.length) {
    $sectionGrid.each(function () {
      new SectionGrid({
        target: $(this),
        scope,
      });
    });
  }

  if ($sectionSliderProjectsFullscreen.length) {
    $sectionSliderProjectsFullscreen.each(function () {
      new SectionSliderProjectsFullscreen({
        target: $(this),
        scope,
      });
    });
  }

  if ($sectionSliderProjects.length) {
    $sectionSliderProjects.each(function () {
      new SectionSliderProjects({
        target: $(this),
        scope,
      });
    });
  }

  if ($sectionSliderImages.length) {
    $sectionSliderImages.each(function () {
      new SectionSliderImages({
        target: $(this),
        scope,
      });
    });
  }


  if ($sectionFixedReveal.length) {
    $sectionFixedReveal.each(function () {
      new SectionFixedReveal({
        target: $(this),
        scope,
      });
    });
  }

  

  if ($hoverGroup.length) {
    $hoverGroup.each(function () {
      new MemHover({
        target: $(this),
        scope,
      });
    });
  }

  
  if ($formContact.length) {
    $formContact.each(function () {
      new FormAJAX({
        target: $(this),
        scope,
      });
    });
  }


  if ($sectionContent.length) {
    $sectionContent.each(function () {
      new SectionContent({
        target: $(this),
        scope,
      });
    });
  }

  if ($sectionTestimonials.length) {
    $sectionTestimonials.each(function () {
      new SectionTestimonials({
        target: $(this),
        scope,
      });
    });
  }

  // refresh animation triggers
  // for Waypoints library
  if (typeof Waypoint !== "undefined") {
    Waypoint.refreshAll();
  }

  // scroll to anchor from URL hash
  if (scrollToHashElement) {
    Scroll.scrollToAnchorFromHash();
  }
}

/**
 * Init Template Components
 * only once after the initial
 * page load
 */
function initComponentsOnce({
  scope = window.$document,
  container = window.$pageWrapper,
}) {

  // Run AJAX navigation
  if (window.membrana.theme.ajax.enabled && window.$barbaWrapper.length) {
    new BARBAJAX({
      target: window.$barbaWrapper,
    });
  }

  // Run page header
  window.membrana.theme.header = new Header({
    target: window.$pageHeader,
    scope,
  });

}
